<template>
       <div>
           <template v-if="row.c_2faSecret">
             <!--  <div   @click.stop="dialog = true" title="2FA Configured: Click to Reset!" class="greenSq" style="margin-top: 8%; margin-right: 5%;cursor: pointer;"></div>-->
               <v-icon
                       style="cursor: pointer;"
                       title="2FA Configured: Click to Reset!"
                       @click.stop="dialog = true"
                       color="green"
               >mdi-security
               </v-icon>
           </template>
        <template v-else>
            <!--<div title="2FA Not configured" class="greySq" style="margin-top: 8%; margin-right: 5%;"></div>-->
            <v-icon
                    style="cursor: pointer;"
                    title="2FA Not configured"
                    color="grey"
            >mdi-security
            </v-icon>
        </template>
           <v-dialog
                   v-model="dialog"
                   max-width="290"
           >
               <v-card>
                   <v-card-title class="headline-box-headline">
                       {{language.componentLabel.labelResetTFA}}
                   </v-card-title>
                   <v-card-text>
                       {{language.componentLabel.labelClickYesTFA}}
                   </v-card-text>
                   <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn
                               color="green darken-1"
                               text
                               @click="dialog = false"
                       > {{language.componentLabel.labelNo}}
                       </v-btn>
                       <v-btn
                               color="green darken-1"
                               text
                               @click="resetTFA(row.n_id,row.n_id_key)"
                       > {{language.componentLabel.labelYes}}
                       </v-btn>
                   </v-card-actions>
               </v-card>
           </v-dialog>
       </div>
    </template>
<script>
      export default {
        data:function(){
          return {
            dialog: false,
          };
        },
        methods: {
          resetTFA(nid,key){
            let caller = this;
            this.frameworkAxiosRequest({
              method: 'POST',
              url: 'serve.php?f=administration&f2=superUserAdministration',
              dataType: 'json',
              data: {
                function: 'resetTFA',
                n_id: nid,
                n_id_key: key,
                requestType: 'ajax',
              },
            })
                .then(response => {
                caller.updateTable(response.data.result.table);
                this.dialog=false;
              })
              .catch(error => {
                console.log(error);
              });
          }
        },
        props: ["row","currentTable"]
      }
    </script>